import React from 'react';
import { Slot } from './Slot';

interface Props {
	slots: {
		[key: number]: {
			x: number;
			y: number;
		};
	};
	radius: number;
	canvasWidth: number;
	canvasHeight: number;
}

export const Slots = (props: Props) => {
	return (
		<>
			{/* Giving the key a number or string type makes TS angry */}
			{Object.keys(props.slots).map((key: any, i) => (
				<Slot
					x={props.slots[key].x * props.canvasWidth}
					y={props.slots[key].y * props.canvasHeight}
					radius={props.radius}
					key={i}
					//index={i} // Uncomment to number the nodes for debugging!
				/>
			))}
		</>
	);
};
