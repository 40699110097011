import React from 'react';
import styles from '../../styles.module.css';
import { Line } from 'react-konva';

interface Props {
	canvasWidth: number;
	canvasHeight: number;
}

export const SeparatorLine = (props: Props) => {
	{
		/* Line starts at 1/5 the width, 1/10 the height*/
	}
	return (
		<Line
			points={[
				(1 / 5) * props.canvasWidth,
				(1 / 10) * props.canvasHeight,
				(1 / 5) * props.canvasWidth,
				(9 / 10) * props.canvasHeight,
			]}
			stroke={styles.borderLightBlue}
			strokeWidth={1}
			lineCap={'round'}
			lineJoin={'round'}
		/>
	);
};
