import React from 'react';
import { Line } from 'react-konva';
import { Slot } from 'src/templates/puzzlePages/NumbersTest';

interface Props {
	lineGroups: number[][];
	slots: { [key: number]: Slot };
	canvasWidth: number;
	canvasHeight: number;
}

export const ConnectingLines = (props: Props) => {
	const { slots, lineGroups, canvasWidth, canvasHeight } = props;
	{
		/* Giving the key a number type makes TS angry */
	}
	return (
		<>
			{props.lineGroups.map((lineGroup: any, i) => {
				// For each line group, draw one line
				const line = lineGroup.map((p: number) => {
					// use the index to associate with a slot, use the slot's relative coordinates
					// and multiply by the canvas space
					return [slots[p].x * canvasWidth, slots[p].y * canvasHeight];
				});
				//flatten the array of arrays
				const lineAsAlternatingXandYCoordinates = [].concat(...line);
				return (
					<Line
						// points takes an array of alternating x,y points ex: [x1,y1,x2,y2]
						points={lineAsAlternatingXandYCoordinates}
						stroke={'#B4D1D5'}
						strokeWidth={5}
						key={i}
					/>
				);
			})}
		</>
	);
};
