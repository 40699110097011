import React from 'react';
import styles from './styles.module.css';

import { Stage, Layer, Circle } from 'react-konva';

import { ArrowButton } from 'src/components/ArrowButton';
import { isOverlapping, Coordinates, convertSlotCoordsToCanvas } from 'src/helpers/CanvasHelpers';

import { NumbersQuestionSpec } from '../../index';
import { MoveableCircle } from './components/MoveableCircle';
import { ConnectingLines } from './components/ConnectingLines/component';
import { SeparatorLine } from './components/SeparatorLine/component';
import { Slots } from './components/Slots/component';
import { Tooltip } from 'src/components/Tooltip';

interface Props {
	currQuestionNum: number;
	questionTotal: number;
	nextQuestion: (answer?: string) => void;
	currQuestionSpec: NumbersQuestionSpec;
}

interface DraggableCircle {
	x: number;
	y: number;
	textNumber: number;
}

interface State {
	draggableCircles: DraggableCircle[];
	slots: { [key: number]: { value: number | null } };
}

const canvasWidth = 700;
const canvasHeight = 500;
const yPadding = canvasHeight / 10;

const initialXPosOffset = canvasWidth / 9;
const initialXPos = canvasWidth / 20;

export class Questions extends React.Component<Props, State> {
	private radius: number = 30;
	private initialCircles: DraggableCircle[] = [];

	state: State = {
		draggableCircles: [],
		slots: {},
	};

	componentDidMount() {
		this.initializeWithNewSpec();
	}

	componentDidUpdate(prevProps: Props) {
		// compare props otherwise, you'll infinitely loop
		if (this.props.currQuestionNum !== prevProps.currQuestionNum) {
			this.initializeWithNewSpec();
		}
	}

	initializeWithNewSpec() {
		// initialize the state.slots based on the props
		let userSlots: { [key: number]: any } = {};
		for (const slot in Object.keys(this.props.currQuestionSpec.slots)) {
			userSlots[slot] = { value: null };
		}

		// initialize the draggable circles
		// for some reason, assigning draggableCircles: this.initialCircles in setState
		// causing a bug where all circles snap back to canvas 0,0
		const dCircles = this.props.currQuestionSpec.availCircles.map((circleNumber, i) => ({
			x: i % 2 ? initialXPosOffset : initialXPos,
			y: (canvasHeight / 10) * i + yPadding,
			textNumber: circleNumber,
		}));

		this.setState({ slots: userSlots, draggableCircles: dCircles });

		// Poplulate the placeholders we use to keep the circles when they are not placed on the graph
		this.initialiseInitialCircles();
	}

	initialiseInitialCircles() {
		this.initialCircles = this.props.currQuestionSpec.availCircles.map((circleNumber, i) => ({
			// alternate for offsetting the even numbered circles
			x: i % 2 ? initialXPosOffset : initialXPos,
			y: (canvasHeight / 10) * i + yPadding,
			textNumber: circleNumber,
		}));
	}

	moveCircleIntoSlot(
		slotId: number,
		convertedSlotCoords: Coordinates,
		draggableCirclesIndex: number
	) {
		// 1. Make a shallow copy of the state
		let draggableCircles = [...this.state.draggableCircles];
		// 2. Make a shallow copy of the item you want to mutate
		let newCircle = draggableCircles[draggableCirclesIndex];
		// 3. Replace with new coords
		newCircle.x = convertedSlotCoords.x;
		newCircle.y = convertedSlotCoords.y;
		// 4. Put it back into our array.
		draggableCircles[draggableCirclesIndex] = newCircle;

		//Set the value in the slot
		let slots = this.state.slots;
		slots[slotId].value = newCircle.textNumber;

		// 5. Set the state to our new copy
		this.setState({ draggableCircles, slots });
	}

	handleDragStart(e: any, draggableCirclesIndex: number) {
		// clear the current coordinates for the circle that's being dragged
		let draggableCircles = [...this.state.draggableCircles];

		const thisCircle = draggableCircles[draggableCirclesIndex];
		// 3. Replace with new coords
		thisCircle.x = 0;
		thisCircle.y = 0;
		// 4. Put it back into our array.
		draggableCircles[draggableCirclesIndex] = thisCircle;
		// 5. Set the state to our new copy
		this.setState({ draggableCircles });

		// check if this circle is in a slot
		// loop through all the slots to see if any have a value with the text number
		let slots = this.state.slots;
		for (const slotId in Object.keys(this.props.currQuestionSpec.slots)) {
			// use the textNumber to see if it's in any of the slots
			const circleIsInASlot = slots[slotId].value === thisCircle.textNumber;
			if (circleIsInASlot) {
				// if the circleIsInASlot, empty that slot, because it has been picked up
				slots[slotId].value = null;

				this.setState({ slots: { ...slots } });
			}
		}
	}

	handleDragEnd(e: any, draggableCirclesIndex: number) {
		const dropLocation = { x: e.target.x(), y: e.target.y() };

		// check if close enough to any slot
		let isSnappedToSlot = false;
		for (const slot in this.props.currQuestionSpec.slots) {
			// convert slot coordinates from ratio to canvas coordinates
			const convertedSlotCoords = convertSlotCoordsToCanvas(
				this.props.currQuestionSpec.slots[slot],
				canvasWidth,
				canvasHeight
			);

			let isCloseToSlot = isOverlapping(dropLocation, convertedSlotCoords, this.radius);

			if (isCloseToSlot) {
				// check if a circle is already in that slot
				if (this.state.slots[slot].value === null) {
					// snap it to the slot
					isSnappedToSlot = true;
					this.moveCircleIntoSlot(Number(slot), convertedSlotCoords, draggableCirclesIndex);
				}
				break;
			}
		}

		if (isSnappedToSlot === false) {
			// out of a slot, send it back to intial position
			e.target.to({
				x: this.initialCircles[draggableCirclesIndex].x,
				y: this.initialCircles[draggableCirclesIndex].y,
				duration: 0.1,
			});
		}
	}

	render() {
		const skipTooltip = `Submits this question with no answer. The question cannot be attempted later.`;
		return (
			<div className={styles.questionsContainer}>
				<div>
					<p className={styles.questionText}>
						Drag all of the digits from 1 to {this.props.currQuestionSpec.availCircles.length} into
						the circles so that the sum of the numbers in each straight line is the same. Each digit
						can only be used once.
					</p>
				</div>
				<div className={styles.canvasContainer}>
					<Stage width={canvasWidth} height={canvasHeight}>
						<Layer>
							<SeparatorLine canvasWidth={canvasWidth} canvasHeight={canvasHeight} />
							{/* Draw Starting Slots for Circles */}
							{this.initialCircles.map((circle, i) => (
								<Circle x={circle.x} y={circle.y} radius={this.radius} key={i} fill={'#DEEEF2'} />
							))}
							{/* Draw lines connecting slots */}
							<ConnectingLines
								lineGroups={this.props.currQuestionSpec.lineGroups}
								slots={this.props.currQuestionSpec.slots}
								canvasHeight={canvasHeight}
								canvasWidth={canvasWidth}
							/>
							{/* Draw slots */}
							<Slots
								slots={this.props.currQuestionSpec.slots}
								radius={this.radius}
								canvasWidth={canvasWidth}
								canvasHeight={canvasHeight}
							/>
							{/* Draw Moveable Circles */}
							{this.state.draggableCircles.map((circle, draggableCirclesIndex) => (
								<MoveableCircle
									x={circle.x}
									y={circle.y}
									textNumber={circle.textNumber}
									radius={this.radius}
									key={draggableCirclesIndex}
									onDragStart={(e: any) => {
										this.handleDragStart(e, draggableCirclesIndex);
									}}
									onDragEnd={(e: any) => {
										this.handleDragEnd(e, draggableCirclesIndex);
									}}
								/>
							))}
						</Layer>
					</Stage>
				</div>
				<div className={styles.submitContainer}>
					<ArrowButton
						caption="Submit Answer"
						onClick={() => {
							this.state.slots &&
								this.props.nextQuestion(
									JSON.stringify({
										slots: this.state.slots,
										lineGroups: this.props.currQuestionSpec.lineGroups,
									})
								);
						}}
						isDisabled={(() => {
							let disable = false;
							for (const slot in Object.keys(this.state.slots)) {
								if (!this.state.slots[slot].value) {
									disable = true;
								}
							}
							return disable;
						})()}
					/>
					<Tooltip caption={skipTooltip}>
						<button
							className={styles.skipButton}
							onClick={() => {
								this.setState({ slots: {} });
								this.props.nextQuestion();
							}}
						>
							{`Skip >`}
						</button>
					</Tooltip>
				</div>
			</div>
		);
	}
}
