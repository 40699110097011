import React from 'react';
import styles from '../../styles.module.css';
import { Text, Circle, Group } from 'react-konva';

interface Props {
	x: number;
	y: number;
	textNumber: number;
	radius: number;
	onDragStart: (e: any) => any;
	onDragEnd: (e: any) => any;
}

export const MoveableCircle = (props: Props) => {
	return (
		<Group
			x={props.x}
			y={props.y}
			draggable
			onDragStart={(e) => {
				props.onDragStart(e);
			}}
			onDragEnd={(e) => {
				props.onDragEnd(e);
			}}
		>
			<Circle
				radius={props.radius}
				stroke={styles.borderLightBlue}
				fill={'#C1F6FE'}
				strokeWidth={1}
				lineJoin={'bevel'}
				shadowBlur={2}
				shadowOffsetY={1}
				shadowColor={'#35A4B4'}
			></Circle>
			<Text
				text={`${props.textNumber}`}
				x={-props.radius / 4}
				y={-props.radius / 4}
				fontSize={18}
				fontStyle={'bold'}
				fontFamily={styles.metropolisMedium}
				align={'center'}
				verticalAlign={'center'}
			/>
		</Group>
	);
};
