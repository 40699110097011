import React from 'react';
import styles from '../../../styles.module.css';
import { Circle, Text } from 'react-konva';

interface Props {
	x: number;
	y: number;
	radius: number;
	index?: number;
}

export const Slot = (props: Props) => {
	return (
		<>
			<Circle
				x={props.x}
				y={props.y}
				radius={props.radius}
				strokeWidth={1}
				fill={'white'}
				stroke={styles.borderLightBlue}
				shadowBlur={3}
				shadowOffsetY={1}
				shadowColor={'#35A4B4'}
			/>
			{props.index !== undefined ? (
				<Text
					x={props.x - props.radius}
					y={props.y - props.radius}
					verticalAlign="middle"
					align="center"
					width={props.radius * 2}
					height={props.radius * 2}
					key={props.index}
					text={props.index?.toString()}
					fontSize={20}
					fontFamily={'Calibri'}
					fill={'green'}
				/>
			) : null}
		</>
	);
};
